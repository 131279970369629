@tailwind base;
@tailwind components;
@tailwind utilities;
.group:hover .group-hover\:block {
  display: block !important;
}
@import url("https://fonts.cdnfonts.com/css/verdana");

.uploadcare--widget_button.uploadcare--widget_button_type_open {
  background-color: rgb(20 184 166);
  color: white;
}
.uploadcare--widget__button_type_open {
  background-color: rgb(20 184 166);
  color: white;
}
.uploadcare--button {
  background-color: rgb(0, 2, 95);
}
.uploadcare--widget__drag {
  color: rgb(20 184 166);
}

.tst {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.shadow {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 1px 4px,
    rgb(51, 51, 51) 0px 0px 0px 3px;
}

.dt-button {
  background: #273272;

  display: inline-block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.25rem;
  border-width: 1px;
  color: #fff;
}
.uploadcare--widget__button .uploadcare--widget__button_type_open {
  background-color: red;
  color: black;
}
.noticeBorder {
  border-top: 8px solid #d12326;
}
.sliderImg {
  animation-duration: 60s;
}

.sliderImg {
  opacity: 0;
  -webkit-transition: opacity 40s linear;
  -moz-transition: opacity 40s linear;
  -ms-transition: opacity 40s linear;
  -o-transition: opacity 40s linear;
  transition: opacity 40s linear;
}
* {
  font-family: Titillium Web;
}
.faculty {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: grid;
  grid-template-columns: 20% 70%;
  padding: 2rem 1rem 2rem 1rem;
}
.facultyEdu {
  /* margin-left: 15rem; */
}
.facultyHr {
  border: 2px solid #ee1c25;
  margin-top: 0.5rem;
}
.edu1 {
  width: 60%;
}
.facultypro {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media only screen and (max-width: 600px) {
  .faculty {
    display: grid;
    padding: 1rem 1rem 1rem 1rem;
    grid-template-columns: repeat(1);
    grid-template-columns: 100%;
  }
  .facultyEdu {
    margin-left: 0rem;
  }
  .edu1 {
    width: 80%;
  }
}
